<template>
  <s-autocomplete
    v-model="leakProfile"
    :items="allItems"
    item-value="id"
    item-title="name"
    :return-object="true"
    :clearable="false"
    :hide-no-data="false"
    spellcheck="false"
    v-bind="$attrs"
  >
    <template #append-item v-if="(allItems?.length ?? 0) > 0">
      <page-loader :items="fetchItems" />
    </template>
    <template #no-data v-if="(allItems?.length ?? 0) === 0 && fetchItems.hasNextPage.value">
      <page-loader :items="fetchItems" />
    </template>
  </s-autocomplete>
</template>

<script setup lang="ts">
const leakProfile = defineModel<LeakProfileShort|null>();

const fetcherOptions = {
  baseURL: '/api/v1/leakprofiles/',
  query: {
    ordering: 'name,created',
  }
}
const fetchItems = useSearchableCursorPaginationFetcher<LeakProfileShort>(fetcherOptions);

useLazyAsyncData(async () => {
  if (!leakProfile.value && toValue(fetchItems.data).length === 0) {
    await fetchItems.fetchNextPage();
  }
});

const allItems = computed(() => {
  if (fetchItems.data.value.length === 0 && fetchItems.pending.value) {
    // Initial fetch of leakprofiles is pending
    return undefined;
  }
  const out = [...toValue(fetchItems.data)];
  if (leakProfile.value && !out.some(lp => lp.id === leakProfile.value?.id)) {
    out.unshift(leakProfile.value);
  }
  return out;
});

watch(leakProfile, () => {
  const out = [...toValue(fetchItems.data)]
  if (leakProfile.value && !out.some(lp => lp.id === leakProfile.value?.id)) {
    // A new leak profile was created probably. Resetting fetcher so that it doesn't disappear later from the selection.
    fetchItems.reset(fetcherOptions);
  }
});

watch(
  () => leakProfile.value?.name,
  (name) => {
    // LeakProfile name was updated. Update the name in the LeakProfile Selection list
    const out = [...toValue(fetchItems.data)]
    const lp = out.find(lp => lp.id === leakProfile.value?.id && lp.name !== name);
    if (lp && name) {
      lp.name = name;
    }
  }
)

defineExpose({
  leakprofiles: allItems,
})
</script>
