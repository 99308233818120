<template>
  <s-btn-icon>
    <v-icon icon="mdi-account" />

    <v-menu activator="parent" location="bottom">
      <v-list>
        <v-list-item
          lines="two"
          :title="auth.user.value!.email"
        />
        <v-divider />
        <v-list-item
          to="/users/self/"
          prepend-icon="mdi-account"
          title="Profile"
        />
        <v-list-item
          @click.stop="localSettings.theme = localSettings.theme === 'light' ? 'dark' : localSettings.theme === 'dark' ? null : 'light'"
          :prepend-icon="localSettings.theme === 'dark' ? 'mdi-weather-night' : localSettings.theme === 'light' ? 'mdi-weather-sunny' : 'mdi-theme-light-dark'"
          :title="localSettings.theme === 'dark' ? 'Theme: Dark' : localSettings.theme === 'light' ? 'Theme: Light' : 'Theme: System'"
        />
        <v-list-item
          @click="auth.logout"
          prepend-icon="mdi-logout"
          title="Log out"
          link
        />
      </v-list>
    </v-menu>
  </s-btn-icon>
</template>

<script setup lang="ts">
const auth = useAuth();
const localSettings = useLocalSettings();
</script>
