<template>
  <v-list
    density="compact"
  >
    <div v-show="(allLeakprofiles?.length || 0) > 0">
      <v-list-subheader>
        <span>Leak Profiles</span>
        <s-btn-icon
          size="small"
          variant="flat"
          color="secondary"
          density="compact"
          class="ml-2"
          :to="`/leakprofiles/new/`"
        >
          <v-icon icon="mdi-plus" />
          <s-tooltip activator="parent" location="top">Create Leak Profile</s-tooltip>
        </s-btn-icon>
      </v-list-subheader>
      <v-list-item>
        <s-leak-profile-selection
          ref="leakProfileSelectionRef"
          :model-value="leakProfile"
          @update:model-value="switchLeakProfile"
          :initial-id="router.currentRoute.value.params.leakprofileId as string"
          variant="underlined"
          density="compact"
          class="mb-4"
        />
        <template #append v-if="mainMenuStore.leakProfile">
          <s-btn-icon
            size="small"
            color="light"
            density="compact"
            :to="`/leakprofiles/${leakProfile?.id}/`"
          >
            <v-icon icon="mdi-pencil" />
            <s-tooltip activator="parent" location="top">Edit Leak Profile</s-tooltip>
          </s-btn-icon>
        </template>
      </v-list-item>

      <v-list-item
        :to="`/leakprofiles/${leakProfile?.id}/accounts/`"
        title="Accounts"
        prepend-icon="mdi-form-textbox-password"
        :disabled="!leakProfile"
      />
      <v-list-item
        :to="`/leakprofiles/${leakProfile?.id}/alerts/`"
        title="Alerts"
        prepend-icon="mdi-alarm-light"
        :disabled="!leakProfile"
      >
        <template #append>
          <v-badge :content="leakProfile?.open_alert_count || '0'" inline />
        </template>
      </v-list-item>
      <v-list-item
        :to="`/leakprofiles/${leakProfile?.id}/reports/`"
        :disabled="!leakProfile || mainMenuStore.leakProfile?.defender_subscription === 'free'"
        prepend-icon="mdi-file-document"
      >
        <template #title>
          <pro-info>Reports</pro-info>
        </template>
      </v-list-item>
    </div>
    <div v-show="(allLeakprofiles?.length || 0) === 0">
      <v-list-subheader title="Setup"/>
      <v-divider />
      <v-list-item
        :to="`/get-started/`"
        title="Get Started"
        prepend-icon="mdi-rocket"
      />
    </div>
    
    <v-list-subheader title="Management" class="mt-8"/>
    <v-divider />
    <v-list-item
      to="/domains/"
      prepend-icon="mdi-earth"
      title="Domains"
    />
    <v-list-item
      to="/users/"
      :disabled="mainMenuStore.leakProfile?.defender_subscription === 'free'"
      prepend-icon="mdi-account"
    >
      <template #title>
        <pro-info>Users</pro-info>
      </template>
    </v-list-item>
    <v-list-item
      to="/users/self/apitokens/"
      :disabled="mainMenuStore.leakProfile?.defender_subscription === 'free'"
      prepend-icon="mdi-code-braces-box"
    >
      <template #title>
        <pro-info>Development</pro-info>
      </template>
    </v-list-item>

    <template v-if="auth.user.value!.is_superuser || auth.user.value!.is_partner">
      <v-list-subheader title="Admin Area" class="mt-8" /> 
      <v-divider />
      <v-list-item to="/lookup/" title="Domain Lookup" prepend-icon="mdi-magnify" />
      <v-list-item v-if="auth.user.value!.is_superuser" href="/admin/" title="Administration" prepend-icon="mdi-file-document" />
    </template>
  </v-list>
</template>

<script setup lang="ts">
const auth = useAuth();
const router = useRouter();
const mainMenuStore = useMainMenuStore();

const leakProfile = computed({
  get: () => mainMenuStore.leakProfile,
  set: (val: LeakProfile|null) => { mainMenuStore.leakProfile = val; },
});

function switchLeakProfile(newLeakProfile: LeakProfileShort|null|undefined) {
  if (!newLeakProfile) {
    return;
  }

  const oldRoute = router.currentRoute.value;
  const oldLeakprofileId = oldRoute.params.leakprofileId as string|undefined;
  if (oldLeakprofileId && oldLeakprofileId !== newLeakProfile.id) {
    // Navigate to new leak profile
    let path = oldRoute.fullPath.replaceAll(oldLeakprofileId, newLeakProfile.id);
    if (Object.entries(router.currentRoute.value.params).length > 1) {
      // If user was in a sub-page, navigate to the parent page
      path = path.split('/').slice(0, 4).join('/');
      router.push(oldRoute.fullPath.replaceAll(oldLeakprofileId, newLeakProfile.id));
    }
    router.push(path);
  } else {
    // Start page: alerts page of first leak profile
    router.replace(`/leakprofiles/${newLeakProfile.id}/accounts/`);
  }
}

const leakProfileSelectionRef = ref<any>();
const allLeakprofiles = computed<LeakProfile[] | undefined>(() => leakProfileSelectionRef.value?.leakprofiles || undefined);
watch([() => router.currentRoute.value.params.leakprofileId], async () => {
  // Update leak profile in mainMenuStore if route changes
  const lpId = router.currentRoute.value.params.leakprofileId as string|undefined;
  if (lpId && leakProfile.value?.id !== lpId) {
    leakProfile.value = await $fetch(`/api/v1/leakprofiles/${lpId}/`);
  }
}, { immediate: true });

watch(allLeakprofiles, async () => {
  // Select initial leak profile on load
  if (allLeakprofiles.value === undefined) {
    return;
  }
  if (!leakProfile.value && allLeakprofiles.value.length > 0) {
    if (['/leakprofiles/', '/login/'].includes(router.currentRoute.value.path)) {
      switchLeakProfile(allLeakprofiles.value[0]!);
    } else if (router.currentRoute.value.params.leakprofileId) {
      leakProfile.value = await $fetch(`/api/v1/leakprofiles/${router.currentRoute.value.params.leakprofileId}/`);
    } else {
      leakProfile.value = await $fetch(`/api/v1/leakprofiles/${allLeakprofiles.value[0]!.id}/`);;
    }
  } else if (allLeakprofiles.value.length === 0) {
    if (['/leakprofiles/', '/login/'].includes(router.currentRoute.value.path)) {
      await nextTick();
      router.push("/get-started/");
    }
  }
}, { immediate: true });
</script>
